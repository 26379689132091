* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif;
  /* text-align: center; */
  color: #19364c;
}

body {
}

button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0px;
}
